@import '~ethos-design-system/src/components/Media/Media.scss';

@mixin for-phone-and-laptop-mid {
  @media (max-width: $laptop-range-mid) {
    @content;
  }
}

@mixin for-tablet-and-laptop-mid {
  @media (min-width: $tablet-range-start) and (max-width: $laptop-range-mid) {
    @content;
  }
}

.wrapper {
  background: linear-gradient(
    135deg,
    #e6f5ec -5.9%,
    rgba(235, 240, 249, 0.98) 138.44%
  );

  &.variant1 {
    background: linear-gradient(
      82deg,
      #04463e -17.99%,
      #045047 2.47%,
      #077b6d 98.16%,
      #078476 121.25%
    ) !important;
  }

  &.variant2 {
    background: linear-gradient(
      82deg,
      #04463e -17.99%,
      #045047 2.47%,
      #077a6d 98.16%,
      #078476 118.96%
    );
  }

  &.variant3 {
    background: linear-gradient(
      82deg,
      #ebfcf4 -0.74%,
      #e4f8f6 98.24%,
      #e3f8f6 110.74%
    );
  }

  &.trustworthy {
    @include for-laptop-and-up {
      position: relative;
      padding: 20px 60px 0;
      background: white !important;
    }
  }

  display: flex;
  justify-content: center;
  overflow: hidden;
  min-height: 100vh;
  @include for-laptop-and-up {
    min-height: auto;
  }

  &.lpGlobalWinnerTreatment1 {
    min-height: auto;
    @include for-phone-only {
      height: 660px;
    }

    @include for-tablet-only {
      height: 700px;
    }
  }

  &.lpGlobalWinnerTreatment2 {
    min-height: auto;
    @include for-phone-and-tablet {
      height: 610px;
    }
  }

  &.lpGlobalWinnerTreatment3 {
    min-height: auto;
    @include for-phone-only {
      height: 470px;
    }

    @include for-tablet-only {
      height: 550px;
    }
  }

  &.lpGlobalWinnerTreatment4 {
    min-height: auto;
    @include for-phone-and-tablet {
      height: 660px;
    }
  }

  .container {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 1440px;
    padding: 32px 120px;
    display: flex;

    &.showBgImageMobile {
      flex-direction: column;
      padding-bottom: 0;
    }

    @include for-phone-and-laptop-mid {
      padding: 32px 24px;
    }

    @include for-tablet-and-laptop-mid {
      padding: 32px 72px;
    }

    &.trustworthy {
      background: linear-gradient(21deg, #fef0e5 -72.1%, #056257 86.88%);
      max-width: 1320px;
      overflow: hidden;

      @include for-laptop-and-up {
        background: linear-gradient(81deg, #056257 -1.36%, #fef0e5 139.47%);
        padding: 32px 120px;
        border-radius: 24px;
        margin-bottom: var(--Space-24);
      }
    }
  }

  .mainContent {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @include for-laptop-and-up {
      align-items: flex-start;
      max-width: 500px;
      width: unset;
    }
  }
}

.trustImages {
  max-width: 510px;
  display: flex;
  align-items: center;
  gap: 42px;
  margin: 48px 0;

  &.lpGlobalWinnerTreatment {
    margin: 48px 0;

    @include for-phone-and-tablet {
      margin-top: 24px;
      margin-bottom: -36px !important;
    }
  }

  .trustImage {
    height: unset;
    width: auto;
    max-width: 100%;
    img {
      height: 89px;
      width: auto;
    }
  }
}

.header {
  color: #056257;

  &.variant1 {
    color: #ffffff;
  }

  &.variant2 {
    color: #ffffff;
  }

  &.variant3 {
    color: #05594f;
  }

  &.trustworthy {
    color: #ffffff;
  }

  &.lpGlobalWinnerTreatment {
    @include for-phone-and-tablet {
      margin-bottom: 16px !important;
    }
  }

  font-family: Cambon;
  font-size: 80px;
  font-weight: 600;
  line-height: 90px;
  letter-spacing: -2.72px;
  margin-bottom: 32px;
  margin-top: 48px;
  width: 100%;
  text-align: center;
  margin-bottom: 36px !important;

  @include for-laptop-and-up {
    text-align: left;
  }

  @include for-phone-and-laptop-mid {
    font-size: 44px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.88px;
    margin-top: 20px;
    margin-bottom: 24px;
  }
}

.subheader {
  color: #056257;

  &.variant1 {
    color: #ffffff;
  }

  &.variant2 {
    color: #ffffff;
  }

  &.variant3 {
    color: #05594f;
  }

  &.trustworthy {
    color: #ffffff;
  }

  text-align: center;
  font-family: Theinhardt;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  letter-spacing: 1.12px;
  text-transform: uppercase;
  margin: 0;

  @include for-tablet-and-up {
    text-align: left;
    font-size: 16px;
  }
}

.logo {
  margin-bottom: 12px;
  img {
    width: 80px;
  }

  @include for-laptop-and-up {
    display: none;
  }
}

.logoWithPartner {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  position: relative;

  .mobileLogo {
    display: flex;
    align-items: center;
    img {
      max-width: 104px;
      height: auto;
    }
  }

  @include for-laptop-and-up {
    display: none;
  }
}

.bgContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;

  &.showBgImageMobile {
    display: block;

    @include for-phone-and-tablet {
      position: relative;
    }
  }

  @include for-laptop-and-up {
    display: block;
  }
}

.bgImage {
  position: absolute;
  right: 200px;
  bottom: 0;
  z-index: 0;

  @include for-tablet-and-laptop-mid {
    right: 100px;

    img {
      width: 460px;
      height: 510px;
    }
  }

  &.lpGlobalWinnerTreatment2 {
    @include for-laptop-and-up {
      img {
        width: 37vw;
        max-width: 470px;
        max-height: 517px;
        height: auto;
      }
    }
  }

  &.lpGlobalWinnerTreatment3 {
    @include for-laptop-and-up {
      img {
        width: 43vw;
        max-width: 506px;
        max-height: 557px;
        height: auto;
      }
    }
  }

  &.showBgImageMobile {
    @include for-phone-and-tablet {
      position: relative;
      display: flex;
      justify-content: center;
    }
  }

  &.huggingImage {
    right: 0 !important;

    img {
      height: auto;
      max-width: 800px;
      width: 60vw;
    }

    @include for-phone-and-tablet {
      img {
        height: 365px;
        width: auto;
        margin-left: 20px;
      }
    }
  }
}

.button {
  max-width: 500px;
  font-size: 20px !important;
  color: #272727;
  text-transform: uppercase !important;

  &.variant1 {
    color: #04463e;
    border: 1px solid #e7fd7f;
    background: #e7fd7f;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
      0px 1px 3px 0px rgba(16, 24, 40, 0.1);

    &:hover {
      background: #ebfc95;
      border: 1px solid #ebfc95;
    }
  }

  &.variant2 {
    color: #04463e;
    border: 1px solid #59f8b1;
    background: #59f8b1;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
      0px 1px 3px 0px rgba(16, 24, 40, 0.1);

    &:hover {
      background: #8efbcae6;
      border: 1px solid #8efbcae6;
    }
  }

  &.variant3 {
    color: #ffffff;
    border: 1px solid #05594f;
    background: #05594f;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
      0px 1px 3px 0px rgba(16, 24, 40, 0.1);

    &:hover {
      background: #05594fbf;
      border: 1px solid #05594fbf;
    }
  }
}
